import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { HomeArticleModel } from '@/types/home';
import { getPathTitle, imgUrl } from '@/shared/utils';
import { AppSettings } from '@/shared/app-common';

const RecommendedArticleContent = ({ dataList }: { dataList?: HomeArticleModel[] }) => {
  const { t } = useTranslation('common');
  const containerRef = useRef(null);
  const router = useRouter();
  // const { locale } = router;

  useEffect(() => {
    const container = containerRef.current;

    const scrollStep = 1;
    const scrollInterval = 30;

    const scroll = () => {
      container.scrollLeft += scrollStep;
      if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className={styles.topContainer}>
          <h2>{t('Recommended Article')}</h2>
          <span
            className={styles.more}
            onClick={() => {
              router.push(AppSettings.blogHost);
            }}
          >
            {t('More >')}
          </span>
        </div>

        <div className={styles.container} ref={containerRef}>
          {dataList?.map((item, index) => {
            return (
              <div
                className={styles.cardContainer}
                key={index + item.title}
                onClick={() => {
                  const getlink = `${AppSettings.blogHost
                    }/instagram-tracking-blog/${item?.article_id}/${getPathTitle(item?.title)}`;
                  router.push(getlink);
                }}
              >
                <Image
                  src={imgUrl(item?.cover_image, 'mid')}
                  alt={item.title}
                  className={styles.icon}
                  width={640}
                  height={360}
                  layout="intrinsic"
                />
                <div className={styles.detailContainer}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.time}>{item.create_time}</div>
                  <div className={styles.desc}>{item.description}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div
          className={styles.bottomMore}
          onClick={() => {
            router.push(AppSettings.blogHost);
          }}
        >
          {t('More >')}
        </div>
      </div>
    );
  }, [dataList]);

  return <>{getContent}</>;
};

export default RecommendedArticleContent;
